import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const UmschuldungEinfachErklart = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Umschuldung – einfach erklärt." showCalc={false} />
            <Article>
                <p>
                    Ein unangemessener{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinssatz
                    </Link>
                    , zu hohe Raten, zu kurze Laufzeit. All das sind Gründe für die Umschuldung von Krediten. Wann und
                    wie du bei der{" "}
                    <Link to="/dienstleistungen/umschuldung/" target="_blank" rel="noreferrer noopener">
                        Umschuldung
                    </Link>{" "}
                    deines Kredits profitierst, welche Kosten dabei entstehen und welche Risiken zu beachten sind,
                    erfährst du im folgenden Beitrag.
                </p>
                <hr />

                <h2>Was versteht man unter Umschuldung?</h2>
                <p>
                    Wird ein neuer{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    aufgenommen, um einen bestehenden zu tilgen, so spricht man von Umschuldung. Während die Summe der
                    Schulden dabei unverändert bleibt, kann damit beispielsweise der{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinssatz
                    </Link>{" "}
                    oder die monatliche Rückzahlungsrate angepasst werden – letzteres etwa durch eine Verlängerung der
                    Laufzeit. Zudem ist es auf diese Weise möglich, das aktuelle Kreditmodell zu adaptieren: So kann ein
                    Kredit mit variablem Zinssatz mithilfe einer Umschuldung durch einen Kredit mit Fixverzinsung
                    ersetzt und dadurch planbarer gestaltet werden.
                </p>
                <hr />

                <h2>Was spricht für eine Umschuldung?</h2>
                <p>
                    Grundsätzlich ist eine Umschuldung immer dann empfehlenswert, wenn ihre Kosten geringer ausfallen
                    als die durch den Kreditwechsel erzielte Ersparnis. Der Nutzen einer Umschuldung steigt folglich
                    mit:
                </p>
                <ul>
                    <li>der Höhe des noch fälligen Kreditbetrags</li>
                    <li>der verbleibenden Laufzeit des Kredits</li>
                    <li>dem Unterschied der Zinssätze des bisherigen und des neuen Kredits</li>
                    <li>
                        der eigenen{" "}
                        <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                            Bonität
                        </Link>
                    </li>
                    <li>geringen Gebühren für die Auflösung des bisherigen Kredits (sogenannte Pönalen)</li>
                </ul>
                <p>
                    Neben Gründen wie der Anpassung des{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    an die aktuelle Lebenssituation, der besseren Planbarkeit oder der Risikominimierung bei
                    Fremdwährungskrediten sind es vor allem die aktuell niedrigen Zinsen am Finanzmarkt, die eine
                    Umschuldung in zahlreichen Fällen zu einer profitablen Option machen. Besonders bei älteren Krediten
                    oder Bauspardarlehen, die nicht selten über deutlich höhere Zinssätze verfügen als rezentere
                    Angebote, lohnt es sich häufig, eine Umschuldung in Erwägung zu ziehen. Durch den Umstieg auf einen
                    Fixzinskredit lässt sich ein momentan niedriger Zinssatz beispielsweise langfristig festlegen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was kostet eine Umschuldung?</h2>
                <p>
                    Bei der Auflösung eines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredites
                    </Link>{" "}
                    in einer Phase der fixen Verzinsung musst du unter Umständen eine Vorfälligkeitsentschädigung
                    entrichten. Im Falle von Verträgen, die ab dem 11.6.2010 abgeschlossen wurden, darf sich diese
                    jedoch auf höchstens 1 % des bereits zurückgezahlten Betrags belaufen. Hast du einen Kredit mit
                    variabler Verzinsung aufgenommen, so kannst du den Vertrag hingegen ohne Strafzahlungen auflösen –
                    sofern du die vertraglich fixierten Fristen einhältst.
                </p>
                <p>
                    Handelt es sich bei deinem Kredit um einen Hypothekarkredit, so musst du die Bank, bei der du den
                    neuen Kredit aufnimmst, in das Grundbuch eintragen lassen. Dies kostet dich 1,2 % vom Wert des
                    Pfandrechtes, das eingetragen werden soll.
                </p>
                <p>
                    Fremdwährungskredite bergen wiederum die Gefahr potenzieller Verluste durch eine ungünstige
                    Entwicklung des jeweiligen Wechselkurses. Diese Verluste werden durch eine Umschuldung realisiert,
                    zugleich jedoch eingeschränkt, indem verhindert wird, dass sie weiter steigen.
                </p>
                <p>
                    Bei der Aufnahme eines neuen Kredites werden zudem Bearbeitungsgebühren fällig, die du ebenfalls
                    berücksichtigen solltest. Auch ist es – etwa um die Übergabe von Sicherheiten durchzuführen – oft
                    notwendig, einen Notar heranzuziehen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Kreditvergleich
                    </a>
                </p>
                <hr />

                <h2>Wie bekomme ich eine Umschuldung?</h2>
                <h3>
                    <strong>Schritt 1</strong>
                </h3>
                <p>
                    Zunächst solltest du deinen derzeitigen Kredit analysieren und überlegen, ob dieser noch zu deinen
                    aktuellen Lebensumständen passt. Entscheidend ist dabei vor allem, ob die monatlichen Raten für dich
                    problemlos leistbar sind. Zudem solltest du die Zinsen deines Kredits mit den derzeit auf dem
                    Finanzmarkt üblichen Zinssätzen vergleichen sowie erwägen, ob du mit deinem Zinsmodell zufrieden
                    bist.
                </p>
                <h3>
                    <strong>Schritt 2</strong>
                </h3>
                <p>
                    Anschließend solltest du dich darüber informieren, mit welchen Kosten der Kreditwechsel verbunden
                    ist – ob beispielsweise eine Vorfälligkeitsentschädigung, eine Grundbuchänderung oder ein Notar zu
                    bezahlen ist.
                </p>
                <h3>
                    <strong>Schritt 3</strong>
                </h3>
                <p>
                    Jetzt geht es darum, eine passende Bank für die Optimierung deines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    zu finden. Hierbei ist es empfehlenswert, sich von einem Wohnbau-Finanzexperten möglichst viele
                    verschiedene Angebote vorlegen zu lassen, wobei es jeweils sämtliche Kostenbestandteile (Zinssatz,
                    Bearbeitungs-, Kontoführungsgebühren etc.) im Blick zu behalten gilt.
                </p>
                <h3>
                    <strong>Schritt 4</strong>
                </h3>
                <p>
                    st der Kreditvertrag unterzeichnet, so wird der bestehende Kredit von der neuen Bank zur Gänze
                    getilgt, wobei Sicherheiten wie die Hypothek im Grundbuch an diese überschrieben werden. Die neue
                    Bank erhält eine Löschungsquittung, die belegt, dass die bisherige Hypothek ihre Gültigkeit verloren
                    hat und veranlasst die Eintragung einer neuen.
                </p>
                <hr />

                <h2>Was ist bei einer Umschuldung zu beachten?</h2>
                <p>
                    Wie bereits erwähnt, ist es zentral, die Kündigungsfrist des bestehenden Kredites zu
                    berücksichtigen. Diese kann im Falle eines Kredits mit variabler Verzinsung bis zu sechs Monate
                    betragen. Entscheidend sind ferner die Effektivzinsen, bei denen neben den Sollzinsen auch sämtliche
                    Nebenkosten und Gebühren inkludiert sind. Auch sollten möglichst viele Angebote verglichen werden,
                    die im Idealfall von einer*einem unabhängigen Kreditvermittler*in eingeholt werden. Wird dir eine
                    Restschuldversicherung angeboten, dann solltest du eingehend prüfen, ob diese in deinem Fall
                    tatsächlich sinnvoll ist. Bei einer Verschlechterung deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    solltest du höchstwahrscheinlich von einer Umschuldung absehen, da es hier zu einer erneuten
                    Überprüfung kommt und der neue Kredit letztendlich schlechtere Konditionen bieten könnte als der
                    bestehende.
                </p>
                <p>
                    Zu Missverständnissen beim Vergleich von Angeboten kommt es nicht selten bei den Zinsen: Einen
                    wesentlichen Aspekt bildet hier die
                    <strong>Unterscheidung von Nominal- und Effektivzinssatz</strong>. Während ersterer sich lediglich
                    auf den Prozentsatz bezieht, mit dem der Betrag des{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    jährlich verzinst wird, berücksichtigt letzterer auch sämtliche Nebenkosten und gibt damit den
                    tatsächlichen Preis des Kredits für die*den Kreditnehmer*in an. Folglich solltest du dich beim
                    Vergleich von Kreditangeboten auf den Effektivzinssatz konzentrieren.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Beratungsgespräch anfordern
                    </a>
                </p>
                <hr />

                <h2>Vorgehen im Krisenfall</h2>
                <p>
                    Tritt eine Krise ein – sei es eine in deinem privaten Umfeld oder eine von gesamtgesellschaftlicher
                    Bedeutung –, so empfehlen wir dir, dich zunächst beraten zu lassen. Im Zuge des Beratungsgesprächs
                    wird analysiert, wo und wie du Einsparungen sowie die Minimierung von Risiken erzielen kannst. Auch
                    hierfür kann eine Umschuldung ein probates Mittel darstellen: Gelingt es dir beispielsweise, einen
                    Kredit, der zu einem Zeitpunkt abgeschlossen wurde, zu dem die Zinsen deutlich höher waren, durch
                    einen mit geringeren Zinsen zu ersetzen, so kann sich deine Ersparnis auf mehrere tausend Euro
                    belaufen. Eine Entlastung bei den monatlichen Raten lässt sich zudem durch eine Verlängerung der
                    Gesamtlaufzeit erwirken. Da es in Krisenzeiten besonders schwierig ist, die Entwicklung der Zinsen
                    zu prognostizieren, kann darüber hinaus ein Wechsel zu einem Fixzinssatz sinnvoll sein.
                </p>
                <p>
                    Ein Risiko für Kreditnehmer*innen stellen im Krisenfall ferner die verschlechterten Konditionen dar,
                    die sie – aufgrund erhöhter Kreditausfälle – vonseiten der Banken zu erwarten haben. Ebenfalls zu
                    beachten ist die geringere Bereitschaft, Kredite an Personen zu vergeben, die in einer von der Krise
                    besonders stark betroffenen Branche tätig sind. Auch eine niedrigere Bewertung deiner Immobilie kann
                    eine Konsequenz der jeweiligen Krise sein.
                </p>
                <p>
                    Folglich kann es zu einer höheren Belehnung derselben kommen, was in verschlechterten
                    Kreditkonditionen resultiert.
                </p>
                <p>
                    Beide Faktoren treten meist mit einiger Verzögerung auf – es ist daher besonders wichtig, zeitnah zu
                    handeln und die Möglichkeit eines Umschuldungskredits rasch zu prüfen.
                </p>
                <hr />

                <h2>Umschuldung und Kreditarten</h2>
                <p>
                    Da <strong>Bauspardarlehen</strong> vor der Finanzkrise einen Zinssatz von zumindest 3 % aufwiesen,
                    derzeit jedoch Wohnkredite mit 1 % Sollzinsen angeboten werden, kann sich eine Umschuldung in diesem
                    Fall äußerst rentabel gestalten. Zu beachten ist hier jedoch die verbleibende Laufzeit: Ist diese zu
                    gering, so lohnt sich ein Kreditwechsel in zahlreichen Fällen nicht mehr. Ist sie allerdings
                    vergleichsweise lang, dann können bereits geringfügig niedrigere Zinsen erheblichen Einsparungen
                    nach sich ziehen.
                </p>
                <p>
                    Komplexer gestaltet sich die Situation dagegen bei einem
                    <strong>Schweizer-Franken-Kredit</strong>: Hier hängt die Entscheidung für bzw. gegen eine
                    Umschuldung zunächst von der Veränderung deiner persönlichen Risikobereitschaft seit dem Zeitpunkt
                    der Kreditaufnahme ab: Je nachdem, wie sich deine Kreditposition sowie deine Einkommenssituation
                    entwickelt hat, neigst du heute möglicherweise dazu, höhere bzw. geringere Risiken einzugehen. Doch
                    auch die potenzielle Entwicklung des Fremdwährungskurses und der Ansparprodukte sowie Prognosen
                    hinsichtlich des zukünftigen Zinsniveaus sollten in die Entscheidung miteinbezogen werden.
                </p>
                <p>
                    Zu einer Entlastung deines Budgets kann schließlich die Umschuldung eines{" "}
                    <strong>Dispositionskredits</strong> beitragen – also jenes{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>
                    , der es dir erlaubt, dein Girokonto zu überziehen. Die Aufnahme eines Privat- oder Konsumkredits
                    ermöglicht es, das Minus auf deinem Konto zu tilgen, und stellt in den meisten Fällen eine
                    günstigere Option als eine permanente Kontoüberziehung dar. Da diese Kreditarten jedoch meist über
                    eine Mindestlaufzeit verfügen, solltest du sicherstellen, dass du dir die monatliche Rate
                    tatsächlich leisten kannst, und darauf achten, dein Konto nicht weiterhin zu überziehen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"umschuldungEinfachErklart"}></BreadcrumbList>
            <ArticleStructuredData page={"umschuldungEinfachErklart"} heading={"Umschuldung – einfach erklärt."} />
        </Layout>
    );
};

export default UmschuldungEinfachErklart;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.umschuldung-einfach-erklart", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
